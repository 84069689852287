import React, { useState, useEffect, useRef } from 'react'
import Letter from './Letter';
import './../css/Letters.css'
import { db } from '../firebase';
import { query, collection, orderBy, getDocs, where, setDoc, doc } from 'firebase/firestore';
import useAuth from '../context/AuthProvider';
import { Fab } from '@mui/material';
import { ArrowDownward } from '@mui/icons-material';

//const LETTERS_DATA = require('../letters/letters.json');

export default function Letters({ filter }) {
    const [letters, setLetters] = useState([]);
    const [visiblity, setVisibility] = useState(true);
    const { user } = useAuth();
    const endLettersRef = useRef(null);
    const lettersRef = useRef(null);

    const getLetters = async () => {
        //setLetters(LETTERS_DATA);
        let q;
        switch (filter) {
            case "bookmarked":
                q = query(collection(db, "letters"), orderBy("timestamp", "asc"), where("bookmark", "==", true));
                break;
            default:
                if (user.admin) {
                    q = query(collection(db, "letters"), orderBy("timestamp", "asc"));
                } else {
                    q = query(collection(db, "letters"), orderBy("timestamp", "asc"), where("visible", "==", true));
                }
        }

        const querySnapshot = await getDocs(q);
        const temp = [];
        querySnapshot.forEach(doc => {
            temp.push({ id: doc.id, ...doc.data() });
        });
        setLetters(temp);
    }

    const handleDown = () => {
        endLettersRef.current.scrollIntoView({ behavior: 'smooth' })
    }

    useEffect(() => {
        getLetters();
        return () => {
            setLetters([]);
        }
    }, [filter]);

    const handleScroll = (e) => {
        if (e.target.scrollTop > 100) {
            setVisibility(false);
        } else {
            setVisibility(true);
        }
    }

    const updateLetter = async (letter) => {
        await setDoc(doc(db, "letters", letter.id), letter);
    }

    return (
        <div className="content" ref={lettersRef} onScroll={handleScroll}>
            {
                letters.map((letter, i) => (
                    <Letter key={i} letter={letter} updateLetter={updateLetter} />
                ))
            }
            {visiblity && (
                <div className="buttons">
                    <Fab onClick={handleDown} color="primary">
                        <ArrowDownward color="white" />
                    </Fab>
                </div>
            )}
            <div ref={endLettersRef} />
        </div>
    )
}
