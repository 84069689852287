import React, { useState } from 'react'
import { Edit, Bookmark, BookmarkBorder, VisibilityOff, Visibility } from '@mui/icons-material'
import { IconButton } from '@mui/material';
import useAuth from '../context/AuthProvider';
import { useNavigate } from 'react-router';

export default function LetterControls({ letter, updateLetter }) {
    const [bookmark, setBookmark] = useState(letter.bookmark); // Set to letter.liked
    const [visibility, setVisibility] = useState(letter.visible);

    const { user } = useAuth();
    const navigate = useNavigate();

    const handleEdit = () => {
        navigate(`/write/${letter.id}`)
    }

    const handleBookmarked = () => {
        setBookmark(!bookmark);
        letter["bookmark"] = !bookmark;
        updateLetter(letter);
    }

    const handleVisibility = () => {
        setVisibility(!visibility);
        letter["visible"] = !visibility;
        updateLetter(letter);
    }

    const editingComponents = (
        <>
            <IconButton onClick={handleEdit}>
                <Edit />
            </IconButton>
            <IconButton onClick={handleVisibility}>
                {
                    visibility ? <Visibility /> : <VisibilityOff />
                }
            </IconButton>
        </>
    )

    return (
        <div className="letter-controls">
            <IconButton onClick={handleBookmarked} className="like-button">
                {
                    bookmark ? (<Bookmark style={styles.likeButton} />
                    ) : <BookmarkBorder />
                }
            </IconButton>
            {
                user.admin && editingComponents
            }
        </div>
    )
}

const styles = {
    likeButton: {
        color: '#C33',
    }
}
