import React from 'react'
import { Navigate } from 'react-router'
import useAuth from '../context/AuthProvider';
import Letters from '../components/Letters';
import Navigation from '../components/Navigation';
import '../css/Dashboard.css'


export default function Dashboard({ filter }) {
    const { user, loading } = useAuth();

    if (loading) {
        return <></>
    } else if (!user) {
        return <Navigate to="/" />
    }

    return (
        <React.Fragment>
            <Navigation />
            <div className="background" />
            <Letters filter={filter}/>
        </React.Fragment>
    )
}

