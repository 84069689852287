import React, { useState, useEffect } from 'react'
import Navigation from '../components/Navigation'
import { Navigate, useParams } from 'react-router';
import useAuth from '../context/AuthProvider';
import './../css/LetterMaker.css'
import Letter from '../components/Letter';
import { db } from '../firebase';
import { addDoc, collection, serverTimestamp, getDoc, doc, setDoc } from 'firebase/firestore/';

const BLANK = {
    date: "",
    header: "",
    content: "",
    footer: ""
}

export default function LetterMaker() {
    const [letter, setLetter] = useState(JSON.parse(JSON.stringify(BLANK)));
    const [updated, setUpdated] = useState(0);

    const { id } = useParams();
    const { user, loading } = useAuth();

    const getLetter = async () => {
        return await getDoc(doc(db, 'letters', id));
    }

    useEffect(() => {
        if (id) {
            getLetter().then(doc => {
                if (doc.exists()) {
                    const data = doc.data();
                    console.log(data)
                    setLetter(data);
                }
            })
        }
    }, [])

    const updateLetter = (field, e) => {
        letter[field] = e.target.value;
        setLetter(letter);
        setUpdated(prev => prev + 1);
    }

    const handlePublish = (e) => {
        e.preventDefault();
        if (!id) {
            if (Object.values(letter).every(val => val)) {
                addDoc(collection(db, "letters"), {...letter, timestamp: serverTimestamp() });
                setLetter(JSON.parse(JSON.stringify(BLANK)));
            }
        } else {
            setDoc(doc(db, 'letters', id), letter);
        }
    }

    if (loading) {
        return <></>
    } else if (!user || !user.admin) {
        return <Navigate to="/" />
    }

    return (
        <>
            <Navigation />
            <div className="background" />
            <div className="container col-md-12">
                <form className="letter-maker col-md-12" onSubmit={handlePublish}>
                    <input
                        id="date"
                        placeholder="Date"
                        onChange={(e) => updateLetter("date", e)}
                        value={letter.date}
                    />
                    <input
                        id="header"
                        placeholder="Header"
                        onChange={(e) => updateLetter("header", e)}
                        value={letter.header}
                    />
                    <textarea
                        placeholder="Letter Content!"
                        onChange={(e) => updateLetter("content", e)}
                        value={letter.content}
                    />
                    <input
                        id="footer"
                        placeholder="Footer"
                        onChange={(e) => updateLetter("footer", e)}
                        value={letter.footer}
                    />
                    <button type="submit">{id ? "Save" : "Publish"}</button>
                </form>
                <div className="preview col-md-12">
                    <Letter letter={letter} update={updated}/>
                </div>
            </div>
        </>
    )
}
