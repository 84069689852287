import { createTheme } from "@mui/material";

export const light = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#ffe0b2',
    },
    secondary: {
      main: '#f50057',
    },
    appbar: {
      main: '#000000DD'
    }
  },
  props: {
    MuiAppBar: {
      color: 'transparent',
    },
  },
});