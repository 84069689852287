import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Letters from './pages/Dashboard';
import Home from './pages/Home';
import { AuthProvider } from './context/AuthProvider';
import LetterMaker from './pages/LetterMaker';
import { ThemeProvider } from '@mui/material';
import { light } from './theme/Themes';

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={light}>
      <BrowserRouter>
        <Routes>
          <Route path="/letters" element={<Letters filter="none"/>} />
          <Route path="/bookmarks" element={<Letters filter="bookmarked"/>} />
          <Route exact path="/write" element={<LetterMaker />} />
          <Route path="/write/:id" element={<LetterMaker />} />
          <Route path="/*" element={<Home />} />
        </Routes>
      </BrowserRouter>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
