import React, { createContext, useState, useMemo, useContext, useEffect } from 'react'
import { auth } from '../firebase';
import { signInAnonymously } from 'firebase/auth';

const AuthContext = createContext({});

export function AuthProvider(props) {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!user) {
            setLoading(true);
            const token = localStorage.getItem("token");            
            if (token) {
                const password = atob(token);
                if (password === process.env.REACT_APP_KATIE_PW) {
                    setUser({ name: 'Katie' });
                } else if (password === process.env.REACT_APP_JOSH_PW) {
                    setUser({ name: 'Josh', admin: true });
                }
            }
            setLoading(false);
        }
    }, []);

    function login(password) {
        setLoading(true);

        let _user = null;
        if (password === process.env.REACT_APP_KATIE_PW) {
            _user = { name: 'Katie' }
        } else if (password === process.env.REACT_APP_JOSH_PW) {
            _user = { name: 'Josh', admin: true }
        }

        if (_user) {
            signInAnonymously(auth)
                .then(() => setUser(_user))
                .catch(err => { });
            localStorage.setItem("token", btoa(password));
        }

        setLoading(false);
        return _user;
    }

    const memoedValue = useMemo(() => ({
        user,
        loading,
        login,
    }), [user, loading]);

    return (
        <AuthContext.Provider value={memoedValue}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default function useAuth() {
    return useContext(AuthContext);
}
