import React, { useState } from 'react'
import petal from "../assets/petal.png"
import Sky from 'react-sky'
import useAuth from '../context/AuthProvider'
import '../css/Home.css'
import { useNavigate } from 'react-router'

export default function Home() {
    const [password, setPassword] = useState("");
    const { login } = useAuth();
    const navigate = useNavigate();

    const handlePasswordChange = (e) => setPassword(e.target.value);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password) {
            const user = login(password);
            if (user) {
                navigate('/letters');
            }
        }
    }

    return (
        <div className="bg-container">
            <Sky
                images={{
                    0: petal
                }}
                how={25}
                time={40}
                size={'150px'}
                background={'transparent'}
            />
            <div className="page-content">
                <div className="box">
                    <h1>
                        Hey! I'm glad you're here<br />
                        I just have to make sure that<br />
                        you're Actually Katie :)
                    </h1>
                    <form onSubmit={handleSubmit}>
                        <input
                            id="password"
                            type="password"
                            placeholder="What's the name of our dog?"
                            onChange={handlePasswordChange}
                            value={password}
                        />
                    </form>
                </div>
            </div>
        </div>
    )
}
