import React from 'react'
import LetterControls from './LetterControls'

export default function Letter({ letter, updated, updateLetter }) {
    return (
        <div id={updated}>
            <div className="letter-pre">
                <h1>{letter.date}</h1>
            </div>
            <div className="letter">
                <LetterControls letter={letter} updateLetter={updateLetter}/>
                <h1 className="header">{letter.header}</h1>
                <div id="content" dangerouslySetInnerHTML={ { __html: `<p>${letter.content}</p>`}}></div>
                <h2 className="footer">{letter.footer}</h2>
            </div>
        </div>
    )
}
