import React from 'react'
import './../css/Navbar.css'
import { Link } from 'react-router-dom';
import useAuth from '../context/AuthProvider';

export default function Navigation() {
    const { user } = useAuth();

    return (
        <nav className="nav-bar">
            <div className="link">
                <Link to="/letters">All Letters</Link>
            </div>
            <div className="link">
                <Link to="/bookmarks">Bookmarks</Link>
            </div>
            {
                user.admin && (
                    <div className="link">
                        <Link to="/write">Write</Link>
                    </div>
                )
            }
        </nav>
    )
}
